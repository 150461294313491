export default function initTabs() {
    const tabButtons = document.querySelectorAll('.tab-button');
    const tabContents = document.querySelectorAll('.tab-content');
    // Ensure the first tab is active on page load
    if (tabButtons.length > 0 && tabContents.length > 0) {
        activateTab(tabButtons[0], tabContents[0]);
    }
    tabButtons.forEach(button => {
        button.addEventListener('click', () => {
            const targetId = button.getAttribute('data-tab-target');
            const targetContent = document.getElementById(targetId);
            if (targetContent) {
                deactivateAllTabs(tabButtons, tabContents);
                activateTab(button, targetContent);
            }
        });
    });
    function deactivateAllTabs(buttons, contents) {
        buttons.forEach(btn => {
            btn.classList.remove('border-black', 'text-black', 'active-tab');
            btn.classList.add('border-transparent');
        });
        contents.forEach(content => {
            content.classList.add('opacity-0');
            setTimeout(() => {
                content.classList.add('hidden');
            }, 300);
        });
    }
    function activateTab(button, content) {
        button.classList.remove('border-transparent');
        button.classList.add('border-black', 'text-black', 'active-tab');
        setTimeout(() => {
            content.classList.remove('hidden', 'opacity-0');
            content.classList.add('opacity-100');
        }, 300);
    }
}

// tabs.js
export default function initTabsBlock() {
    const tabbedAccordionBlocks = document.querySelectorAll('.tabbed-accordion-block-with-image');
    tabbedAccordionBlocks.forEach((block) => {
        const tabs = block.querySelectorAll('.tab-button');
        const panels = block.querySelectorAll('.tab-panel');
        tabs.forEach((tab, index) => {
            tab.addEventListener('click', () => {
                // Update active tab button
                // tabs.forEach((t) =>
                //   t.classList.remove('text-red-500', 'border-b-2', 'border-red-500')
                // );
                // tab.classList.add('text-red-500', 'border-b-2', 'border-red-500');
                // Show corresponding tab panel
                panels.forEach((panel) => panel.classList.add('hidden'));
                const targetPanel = block.querySelector(`.tab-panel[data-tab="${index}"]`);
                if (targetPanel) {
                    targetPanel.classList.remove('hidden');
                }
            });
        });
        // Activate the first tab by default
        if (tabs[0]) {
            tabs[0].click();
        }
    });
}

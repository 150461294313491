// accordionWithImage.js
// export default function initAccordionWithImage() {
//   const accordionBlocks = document.querySelectorAll(
//     '.accordion-block-with-image'
//   );
//   accordionBlocks.forEach((accordionBlock) => {
//     const detailsElements = accordionBlock.querySelectorAll(
//       'details[data-image]'
//     );
//     const imageContainer = accordionBlock.querySelector('.accordion-image');
//     if (!imageContainer) {
//       console.error('Accordion image container not found.');
//       return;
//     }
//     detailsElements.forEach((details) => {
//       details.addEventListener('toggle', () => {
//         if (details.open) {
//           const imageUrl = details.dataset.image;
//           if (imageUrl) {
//             imageContainer.style.backgroundImage = `url(${imageUrl})`;
//             imageContainer.classList.remove('fade-in');
//             void imageContainer.offsetWidth; // Trigger reflow for animation
//             imageContainer.classList.add('fade-in');
//           }
//         }
//       });
//     });
//   });
// }
export default function initAccordionWithImage() {
    const accordionBlock = document.querySelector('.accordion-block-with-image');
    if (!accordionBlock)
        return;
    const detailsElements = accordionBlock.querySelectorAll('details');
    const imageContainer = accordionBlock.querySelector('.accordion-image');
    detailsElements.forEach((details) => {
        details.addEventListener('click', () => {
            // Close all other details
            detailsElements.forEach((otherDetails) => {
                if (otherDetails !== details) {
                    otherDetails.open = false;
                }
            });
            // Update the image based on the clicked accordion
            const imageUrl = details.dataset.image;
            if (imageUrl && imageContainer) {
                imageContainer.style.backgroundImage = `url(${imageUrl})`;
                imageContainer.classList.remove('fade-in');
                void imageContainer.offsetWidth; // Trigger reflow for animation
                imageContainer.classList.add('fade-in');
            }
        });
    });
}

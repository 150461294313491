// carousels.js
import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
// Autoplay Carousel (default if no data-type)
export function initAutoplayCarousel() {
    const carousels = document.querySelectorAll('.swiper:not([data-type])');
    carousels.forEach((carousel) => {
        new Swiper(carousel, {
            modules: [Autoplay, Pagination],
            slidesPerView: 1,
            spaceBetween: 32,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: carousel.querySelector('.swiper-pagination'),
                clickable: true,
                dynamicBullets: true,
            },
            breakpoints: {
                768: { slidesPerView: 3 },
            },
        });
    });
}
// Large Carousel
export function initLargeCarousel() {
    const carousels = document.querySelectorAll('.swiper[data-type="large-carousel"]');
    carousels.forEach((carousel) => {
        new Swiper(carousel, {
            modules: [Autoplay, Pagination],
            slidesPerView: 1,
            spaceBetween: 16,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
            },
            pagination: {
                el: carousel.querySelector('.swiper-pagination'),
                clickable: true,
            },
        });
        // Pause autoplay on mouse enter
        carousel.addEventListener('mouseenter', () => {
            swiper.autoplay.stop();
        });
        // Resume autoplay on mouse leave
        carousel.addEventListener('mouseleave', () => {
            swiper.autoplay.start();
        });
    });
}
// Category Carousel
export function initCategoryCarousel() {
    const carousels = document.querySelectorAll('.swiper[data-type="category-carousel"]');
    carousels.forEach((carousel) => {
        new Swiper(carousel, {
            modules: [Autoplay, Pagination],
            slidesPerView: 1.3,
            spaceBetween: 16,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
            },
            pagination: {
                el: carousel.querySelector('.swiper-pagination'),
                clickable: true,
            },
            breakpoints: {
                640: { slidesPerView: 1.3 },
                768: { slidesPerView: 2.3 },
                1024: { slidesPerView: 2.3 },
            },
        });
        // Pause autoplay on mouse enter
        carousel.addEventListener('mouseenter', () => {
            swiper.autoplay.stop();
        });
        // Resume autoplay on mouse leave
        carousel.addEventListener('mouseleave', () => {
            swiper.autoplay.start();
        });
    });
}
export function initMemberCarousel() {
    const carousels = document.querySelectorAll('.swiper.member-carousel');
    carousels.forEach((carousel) => {
        const swiper = new Swiper(carousel, {
            modules: [Autoplay, Pagination],
            slidesPerView: 1.3,
            spaceBetween: 48,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
            },
            breakpoints: {
                640: { slidesPerView: 1.3 },
                768: { slidesPerView: 2.3 },
                1024: { slidesPerView: 2.3 },
            },
            pagination: {
                el: carousel.querySelector('.swiper-pagination'),
                clickable: true,
            },
        });
        // Pause autoplay on mouse enter
        carousel.addEventListener('mouseenter', () => {
            swiper.autoplay.stop();
        });
        // Resume autoplay on mouse leave
        carousel.addEventListener('mouseleave', () => {
            swiper.autoplay.start();
        });
    });
}

export default function initTabbedAccordionWithImage() {
    const tabbedAccordionBlocks = document.querySelectorAll('.tabbed-accordion-block-with-image');
    tabbedAccordionBlocks.forEach((block) => {
        const tabs = block.querySelectorAll('.tab-button');
        const panels = block.querySelectorAll('.tab-panel');
        // Initialize Tabs
        tabs.forEach((tab, index) => {
            tab.addEventListener('click', () => {
                // Update active tab button
                tabs.forEach((t) => t.classList.remove('bg-[#FF9091]'));
                tab.classList.add('bg-[#FF9091]');
                // Show corresponding tab panel
                panels.forEach((panel) => panel.classList.add('hidden'));
                const targetPanel = block.querySelector(`.tab-panel[data-tab="${index}"]`);
                targetPanel.classList.remove('hidden');
                // Reset accordion image for the first section in the active tab
                const firstAccordionSection = targetPanel.querySelector('details[data-image]');
                const imageContainer = block.querySelector('.accordion-image');
                if (firstAccordionSection && imageContainer) {
                    const firstImageUrl = firstAccordionSection.dataset.image || '';
                    imageContainer.style.backgroundImage = `url(${firstImageUrl})`;
                }
            });
        });
        // Initialize Accordion Functionality
        const initializeAccordions = (panel) => {
            const detailsElements = panel.querySelectorAll('details');
            const imageContainer = block.querySelector('.accordion-image');
            detailsElements.forEach((details) => {
                details.addEventListener('click', () => {
                    // Close all other details in the same panel
                    detailsElements.forEach((otherDetails) => {
                        if (otherDetails !== details) {
                            otherDetails.open = false;
                        }
                    });
                    // Update the image based on the clicked accordion
                    const imageUrl = details.dataset.image;
                    if (imageUrl && imageContainer) {
                        imageContainer.style.backgroundImage = `url(${imageUrl})`;
                        imageContainer.classList.remove('fade-in');
                        void imageContainer.offsetWidth; // Trigger reflow for animation
                        imageContainer.classList.add('fade-in');
                    }
                });
            });
        };
        // Initialize accordions for all panels
        panels.forEach((panel) => initializeAccordions(panel));
    });
}

export default function initBlSocialShare() {
    // Get and sanitize the current URL
    const currentUrl = new URL(window.location.href).toString();
    // Configure the share links
    const links = [
        { id: 'share-x', url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}` },
        { id: 'share-linkedin', url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}` },
        { id: 'share-email', url: `mailto:?body=${encodeURIComponent(currentUrl)}` },
    ];
    // Dynamically set href attributes for each share link
    links.forEach(({ id, url }) => {
        const element = document.getElementById(id);
        if (element)
            element.setAttribute('href', url);
    });
    // Add event listener for copying the URL to clipboard
    const copyButton = document.querySelector('[data-copy-url]');
    if (copyButton) {
        copyButton.addEventListener('click', (event) => {
            event.preventDefault(); // Only prevent default for the copy action
            navigator.clipboard.writeText(currentUrl)
                .then(() => alert('URL has been copied to the clipboard.'))
                .catch(err => console.error('Unable to copy URL:', err));
        });
    }
}

export default function initSearchDrawer() {
    const searchIcon = document.getElementById('search-icon');
    const searchDrawerWrapper = document.getElementById('search-drawer-wrapper');
    if (searchIcon && searchDrawerWrapper) {
        // Event listener to toggle the search drawer visibility
        searchIcon.addEventListener('click', (event) => {
            event.preventDefault(); // Prevent default link behavior only for the search icon
            searchDrawerWrapper.classList.toggle('hidden');
            searchDrawerWrapper.classList.toggle('block');
        });
    }
}

import domReady from '@roots/sage/client/dom-ready';
import './components/fontAwesome';
import { initAutoplayCarousel, initLargeCarousel, initCategoryCarousel, initMemberCarousel, } from '@scripts/components/swiper';
import initFaqs from "@scripts/components/faq";
import initAccordionWithImage from '@scripts/components/accordionWithImage';
import initTabbedAccordionWithImage from '@scripts/components/tabbedAccordionWithImage';
import initSidebar from "@scripts/components/sidebar";
import initMainNav from "@scripts/components/mainNav";
import initWoocommerce from "@scripts/components/woocommerce";
import initTypeform from "@scripts/components/typeform";
import initBlSocialShare from "@scripts/components/bl-social-share";
import initTabs from './components/initTabs';
import initTabsBlock from './components/initTabsBlock';
import initHandleSecondaryNavScroll from './components/handleScroll';
import initSearchDrawer from './components/searchDrawer';
/**
 * Application entrypoint
 */
domReady(async () => {
    initMainNav();
    initSearchDrawer();
    initHandleSecondaryNavScroll();
    initAccordionWithImage();
    initTabbedAccordionWithImage();
    initSidebar();
    initBlSocialShare();
    initAutoplayCarousel();
    initMemberCarousel();
    initLargeCarousel();
    initCategoryCarousel();
    initTabsBlock();
    initFaqs();
    initTabs();
    initWoocommerce();
    initTypeform();
});
/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot)
    import.meta.webpackHot.accept(console.error);

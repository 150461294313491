export default function initHandleSecondaryNavScroll() {
    let lastScrollTop = 0;
    let scrollDownAccumulated = 0; // Tracks scroll distance when scrolling down
    let scrollUpAccumulated = 0; // Tracks scroll distance when scrolling up
    const secondaryNav = document.getElementById('secondaryNav');
    const scrollDownThreshold = 300; // Threshold for scrolling down (300px)
    const scrollUpThreshold = 10; // Smaller threshold for scrolling up (100px)
    if (!secondaryNav)
        return; // Exit if element doesn't exist
    window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // Calculate the scroll delta
        const scrollDelta = scrollTop - lastScrollTop;
        if (scrollDelta > 0) {
            // Scrolling down
            scrollDownAccumulated += scrollDelta;
            scrollUpAccumulated = 0; // Reset the opposite direction
            if (scrollDownAccumulated >= scrollDownThreshold) {
                secondaryNav.classList.add('-translate-y-full');
                scrollDownAccumulated = 0; // Reset after applying the effect
            }
        }
        else if (scrollDelta < 0) {
            // Scrolling up
            scrollUpAccumulated -= scrollDelta; // Use -scrollDelta to make it positive
            scrollDownAccumulated = 0; // Reset the opposite direction
            if (scrollUpAccumulated >= scrollUpThreshold) {
                secondaryNav.classList.remove('-translate-y-full');
                scrollUpAccumulated = 0; // Reset after applying the effect
            }
        }
        // Update last scroll position
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling values
    });
}
